<template>
  <div id="experiments-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link class="btn btn-secondary mb-5 mr-3" to="/experiments">Zurück zur Übersicht</router-link>
    <button @click="saveExperiment" class="btn btn-primary mb-5">Experiment speichern</button>

    <div v-if="step === 0" class="card">
      <div class="card-header">
        <h3 class="mb-0">Neues Experiment</h3>
      </div>
      <div class="card-body">
        <h4>Was willst du testen?</h4>
        <p>
          Bevor du loslegst: <br>
          Willst du dein Experiment auf einer Idee, die du schon erstellt hast basieren?
        </p>

        <div class="form-group">
          <label>Ideen zum verbinden:</label>
          <select v-model="newExperiment.ideaId" class="form-control">
            <option value="">Idee auswählen</option>
            <option v-for="(idea, index) in ideas" :value="idea.id" :key="index">{{ idea.title }}</option>
          </select>
        </div>

        <div v-if="idea">
          <h4>Super, willst du die Daten für das Experiment einfach importieren?</h4>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <h6>{{ idea.title }}</h6>
                  <p>
                    {{ idea.userProblem }}
                  </p>
                  <h6>Hypothese:</h6>
                  <ul>
                    <li>Wenn: {{ idea.hypothesis.ifTerm }}</li>
                    <li>Dann: {{ idea.hypothesis.thenTerm }}</li>
                    <li>Weil: {{ idea.hypothesis.becauseTerm }}</li>
                  </ul>
                </div>
                <div class="col-sm-6">
                  <h6>Beschreibung:</h6>
                  <p>
                    {{ idea.description }}
                  </p>

                  <h6>Verknüpfte Findings</h6>
                  <ul>
                    <li v-for="(finding, index) in linkedFindings" :key="index">{{ finding.title }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button @click="step++" class="btn btn-secondary btn-block">Ohne Import weiter</button>
          </div>
          <div v-if="idea" class="col-sm-6">
            <button @click="importIdea" class="btn btn-primary btn-block">Idee importieren</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step === 1" class="card">
      <div class="card-header">
        <h3 class="mb-0">Neues Experiment</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <h4>Wie willst du dein Experiment nennen?</h4>
          <input v-model="newExperiment.title" type="text" class="form-control">
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <h4>Beschreibe dein Experiment:</h4>
              <textarea v-model="newExperiment.description" rows="5" class="form-control"></textarea>
            </div>
          </div>
          <div class="col-sm-6">
            <h5 class="mb-3">Hypothese:</h5>
            <div class="form-row">
              <div class="col-2">
                <label>Wenn: </label>
              </div>
              <div class="col-10">
                <input v-model="newExperiment.hypothesis.ifTerm" class="form-control mb-2" type="text">
              </div>
            </div>
            <div class="form-row">
              <div class="col-2">
                <label>Dann: </label>
              </div>
              <div class="col-10">
                <input v-model="newExperiment.hypothesis.thenTerm" class="form-control mb-2" type="text">
              </div>
            </div>
            <div class="form-row">
              <div class="col-2">
                <label>Weil: </label>
              </div>
              <div class="col-10">
                <input v-model="newExperiment.hypothesis.becauseTerm" class="form-control mb-2" type="text">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button @click="step--" class="btn btn-secondary btn-block">Schritt zurück</button>
          </div>
          <div class="col-sm-6">
            <button @click="step++" class="btn btn-primary btn-block">Weiter zu den Details</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step === 2" class="card">
      <div class="card-header">
        <h3>
          {{ newExperiment.title }}
        </h3>
      </div>
      <div class="card-body">
        <h4>Deine Varianten:</h4>
        <p>
          Füge hier deine Varianten hinzu, um das Experiment visueller zu machen.
        </p>
        <div class="row">
          <div v-for="(variation, index) in newExperiment.variations" class="col-sm-6 mb-3" :key="index">
            <div class="card h-100">
              <div class="card-body">
                <div class="form-group">
                  <label>Name der Variante</label>
                  <input type="text" v-model="variation.title" placeholder="Variantentitel eingeben" class="form-control">
                </div>

                <div class="form-group">
                  <label>Kurzbeschreibung</label>
                  <textarea v-model="variation.description" rows="4" placeholder="Beschreibung hinzufügen" class="form-control"></textarea>
                </div>

                <label v-if="variation.images.length">Konzept-Vorschau</label>
                <div class="img-wrapper">
                  <img v-for="(image, index) in variation.images" :src="image.url" alt="" class="img-fluid" :key="index">
                </div>
              </div>
              <div class="card-footer">
                <div class="form-group">
                  <label>Varianten-Konzept hinzufügen</label>
                  <FileUpload @filesSaved="filesSaved($event, index)"
                              :filename="newExperiment.id + '/' + variation.title"
                              :small="true"
                  ></FileUpload>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card h-100">
              <div class="card-body">
                <i class="fad fa-circle-plus fa-2x text-success"></i>
                <label>Titel:</label>
                <input v-model="newVariation.title" type="text" class="form-control">

                <button @click="addVariation" class="btn btn-primary mt-3">Variante hinzufügen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button @click="step--" class="btn btn-secondary btn-block">Schritt zurück</button>
          </div>
          <div class="col-sm-6">
            <button @click="step++" class="btn btn-primary btn-block">Weiter zu den Details</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 3" class="card">
      <div class="card-header">
        <h3>
          {{ newExperiment.title }}
        </h3>
      </div>
      <div class="card-body">
        <h4>Das Testsetup</h4>
        <p>
          Wie ist dein Experiment aufgebaut? Von Testeintritt bis Success-Metriken solltest du hier festlegen, wie du deine Hypothese valide misst und
          bewertest.
        </p>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <h5>Das Main-Goal: </h5>
              <select v-model="newExperiment.goal" @change="addGoal" class="form-control">
                <option value="">Main-Goal auswählen</option>
                <option v-for="(goal, index) in goals" :value="goal" :key="index">{{ goal }}</option>
              </select>
            </div>

            <div class="form-group">
              <h5>In welchem Zeitraum findet das Experiment statt?</h5>
              <div class="form-row">
                <div class="col">
                  <label>Start:</label>
                  <input v-model="newExperiment.startDate" type="date" class="form-control">
                </div>
                <div class="col">
                  <label>Ende</label>
                  <input v-model="newExperiment.endDate" type="date" class="form-control">
                </div>
              </div>
            </div>

            <h5>Dein Funnel:</h5>
            <p>
              Gib hier deine Startwerte ein oder überspringe diesen Schritt, um sie später hinzuzufügen.
            </p>
            <CalculationFunnel :funnel-steps="newExperiment.variations[0].funnelSteps" :editable="true" currency="€"></CalculationFunnel>

          </div>
          <div v-if="resultsReady" class="col-md-6">
            <h6>Details:</h6>
            <table class="table table-striped table-sm">
              <tbody>
              <tr>
                <th scope="row">Visitors</th>
                <td>{{ newExperiment.visitors }}</td>
              </tr>
              <tr>
                <th scope="row">Conversions</th>
                <td>{{ newExperiment.conversions }}</td>
              </tr>
              <tr>
                <th scope="row">Uplift</th>
                <td>{{ (newExperiment.uplift*100).toFixed(2) }} %</td>
              </tr>
              <tr>
                <th scope="row">Significant?</th>
                <td>{{ newExperiment.significant ? 'Yes' : 'No' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button @click="step--" class="btn btn-secondary btn-block">Schritt zurück</button>
          </div>
          <div class="col-sm-6">
            <button @click="step++" class="btn btn-primary btn-block">Weiter zu den Details</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 4" class="card">
      <div class="card-header">
        <h3>
          {{ newExperiment.title }}
        </h3>
      </div>
      <div class="card-body">
        <h4>Tagge dein Experiment zusätzlich, um es später filtern zu können oder überspringe diesen Schritt und speichere das Experiment.</h4>
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group">
              <h5>Seitentyp</h5>
              <select v-model="newExperiment.pagetypes" multiple class="form-control">
                <option value="" disabled>Seitentypen auswählen</option>
                <option v-for="(pagetype, index) in pagetypes" :value="pagetype" :key="index">{{ pagetype }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <h5>Device</h5>
              <select v-model="newExperiment.devices" multiple class="form-control">
                <option value="" disabled>Devices auswählen</option>
                <option v-for="(device, index) in devices" :value="device" :key="index">{{ device }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <h5>Patterns</h5>
              <select v-model="newExperiment.patterns" multiple class="form-control">
                <option value="" disabled>Patterns auswählen</option>
                <option v-for="(pattern, index) in patterns" :value="pattern" :key="index">{{ pattern }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button @click="step--" class="btn btn-secondary btn-block">Schritt zurück</button>
          </div>
          <div v-if="idea" class="col-sm-6">
            <button @click="saveExperiment" class="btn btn-primary btn-block">Experiment speichern</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CalculationFunnel from "@/components/CalculationFunnel";
import FileUpload from "@/components/FileUpload";
export default {
  name: 'ExperimentsAdd',
  components: {FileUpload, CalculationFunnel},
  data() {
    return {
      step: 0,
      resultsReady: false,
      newExperiment: {
        id: "",
        ideaId: "",
        title: "",
        lead: "",
        slotId: "",
        startDate: null,
        endDate: null,
        visitors: 0,
        goal: "",
        conversions: 0,
        significant: false,
        uplift: 0,
        total: "",
        description: "",
        userProblem: "",
        hypothesis: {
          ifTerm: "",
          thenTerm: "",
          becauseTerm: "",
        },
        variations: [
          {
            title: "Control",
            images: [],
            funnelSteps: [
              {
                title: "Visits",
                visits: 1,
                visitsUnique: 1,
              },
            ],
          }
        ],
        devices: [],
        pagetypes: [],
        patterns: [],
        tags: [],
      },
      newVariation: {
        title: "",
        images: [],
        funnelSteps: [],
      },
      newFunnelStep: {

      }
    }
  },
  computed: {
    ideas() {
      return this.project.ideas;
    },
    idea() {
      return this.project.ideas.find(i => i.id === this.newExperiment.ideaId);
    },
    linkedFindings() {
      return this.project.findings.filter(f => this.idea.findingIds.indexOf(f.id) >= 0);
    },
    goals() {
      return this.$store.state.experimentFilters.goals;
    },
    pagetypes() {
      return this.$store.state.experimentFilters.pagetypes;
    },
    devices() {
      return this.$store.state.experimentFilters.devices;
    },
    patterns() {
      return this.$store.state.experimentFilters.patterns;
    },
  },
  methods: {
    add(prop, $event) {
      this.newExperiment[prop].push($event.target.value);
      $event.target.value = "";
    },
    remove(prop, index) {
      this.newExperiment[prop].splice(index, 1);
    },
    addVariation() {
      this.newExperiment.variations.push(this.newVariation);
      this.newVariation = {
        title: "",
        images: [],
        funnelSteps: [],
      };
    },
    filesSaved($event, index) {
      let files = JSON.parse(JSON.stringify($event));
      this.newExperiment.variations[index].images = files;
      console.debug('filesSaved', files, this.newExperiment.variations[index].images);
    },
    addGoal() {
      this.newExperiment.variations.forEach(v => {
        v.funnelSteps.push({
          title: this.newExperiment.goal,
          visits: 1,
          visitsUnique: 1
        });
      });
    },
    importIdea() {
      this.newExperiment.title = this.idea.title;
      this.newExperiment.description = this.idea.description;
      this.newExperiment.hypothesis = this.idea.hypothesis;
      this.step = 2;
    },
    saveExperiment() {
      let files = this.newExperiment.variations.reduce((acc, v) => acc.concat(v.images), []);
      this.$store.dispatch('upload/uploadFiles', { files, path: this.project._id })
          .then(res => {
            res.files.forEach(file => {
              this.newExperiment.variations.forEach(variation => {
                let index = variation.images.findIndex(v => v.id === file.id);
                console.debug('files foreach', file, variation.images[index]);
                if (index >= 0) {
                  this.$set(variation.images, index, file);
                  console.debug('images', variation.images);
                }
              });
            });
            if (this.$route.params.id) {
              let index = this.project.experiments.find(e => e.id === this.newExperiment.id);
              this.project.experiments.splice(index, 1, this.newExperiment);
            } else {
              this.project.experiments.push(this.newExperiment);
            }
            this.$store.dispatch('project/updateProjectByProp', {prop: 'experiments', data: this.project.experiments})
                .then(() => {
                  this.$router.push('/experiments');
                })
          });
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newExperiment = this.project.experiments.find(p => p.id === this.$route.params.id);
    } else {
      this.newExperiment.id = this.generateId();
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-toggle, .dropdown-item {
  cursor: pointer;
}
.dropdown-menu {
  display: none;

  &.show {
    display: block;
  }
}
</style>